import { gql } from 'apollo-angular';

export const GET_RECOMMENDED_UNIS = gql`
	query getRecommendedUnis($product: Product = UNDERGRADUATE, $limit: Int = null) {
		recommendedUniversities(product: $product, limit: $limit) {
			id
			name
			short_name
			slug
			logo
			paid_features
			privacy_policy_url
			courses_count
			saved
			order
			profile(product: $product) {
				cover_image
				external_url
				external_prospectus_url
				external_events_url
			}
			stats {
				recommended_percentage
				rating
			}
			address {
				region
			}
		}
	}
`;

export const GET_CLEARING_RECOMMENDED_UNIS = gql`
	query getClearingRecommendedUniversities($limit: Int = null) {
		clearingRecommendedUniversities(limit: $limit) {
			id
			name
			logo
			region
			paid_features
			region
			recommended_percentage
			external_url
			phone_number
			slug
			cover_image
			saved
			privacy_policy_url
			order
		}
	}
`;
